import React from "react"
import { Link, graphql } from "gatsby"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

const EssayIndex = ({
  data,
  pageContext: { nextPagePath, previousPagePath },
}) => {
  const posts = data.allWpPost.nodes

  if (!posts.length) {
    return (
      <Layout isHomePage>
        <p>
          No blog posts found. Add posts to your WordPress site and they'll
          appear here!
        </p>
      </Layout>
    )
  }

  const archiveFeaturedImage = {
    fluid: getImage(data.wpMediaItem.localFile),
    alt: data.wpMediaItem.altText || "Essays",
  }

  return (
    <Layout isHomePage>
      <div className="red-to-violet h-[200px] featured-image-wrapper">
        <div className="featured-image absolute -z-20 h-[500px] md:h-[700px] lg:h-[1024px] w-full">
          <GatsbyImage
            image={archiveFeaturedImage.fluid}
            alt={archiveFeaturedImage.alt}
            className="!absolute -z-20 !w-screen"
          />
          <div className="absolute -z-10 h-full w-full mix-blend-screen"></div>
          <div
            className="absolute h-[200px] sm:h-[150px] md:h-[200px] lg:h-[400px] bottom-0 w-full bg-contain"
            style={{
              backgroundImage:
                "url(https://wp.transtechsocial.org/wp-content/uploads/2023/01/white-fade-2.png)",
            }}
          ></div>
        </div>
      </div>
      <div className="red-to-violet archive">
        <div id="essays" className="max-w-7xl mx-auto px-4 sm:px-8">
          <div className="w-full">
            <h1
              itemProp="headline"
              className="w-full font-hero text-4xl md:text-6xl lg:text-7xl leading-none text-black mb-12 lg:mb-24"
            >
              Essays
            </h1>
          </div>

          <div className="relative grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between items-strech gap-8 mb-32">
            {posts.map(post => {
              const title = post.title
              const featuredImage = {
                fluid: getImage(post.featuredImage?.node.localFile),
                alt: post.featuredImage?.node?.altText || post.title,
              }

              return (
                <>
                  <article
                    key={post.uri}
                    itemScope
                    itemType="http://schema.org/Article"
                    className="relative flex flex-col justify-end rounded-lg"
                  >
                    <div className="absolute w-full p-4 bg-gradient-to-b from-transparent to-ttBlack80 rounded-b-lg">
                      <h2
                        className="font-bold text-white text-lg sm:text-2xl lg:text-xl xl:text-2xl mb-2"
                        itemProp="headline"
                      >
                        {parse(title)}
                      </h2>
                      <small className="text-ttGray uppercase">
                        {post.date}
                      </small>
                    </div>
                    <GatsbyImage
                      image={featuredImage.fluid}
                      alt={featuredImage.alt}
                      className="relative -z-10 mb-0 drop-shadow-harsh border-2 border-black rounded-lg"
                    />
                    <Link
                      to={post.uri}
                      className="absolute h-full w-full z-10"
                    ></Link>
                  </article>
                </>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default EssayIndex

export const pageQuery = graphql`
  query WordPressEssayArchive($offset: Int!, $postsPerPage: Int!) {
    allWpPost(
      filter: {
        categories: { nodes: { elemMatch: { name: { eq: "Essay" } } } }
      }
      sort: { fields: [date], order: DESC }
      limit: $postsPerPage
      skip: $offset
    ) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        excerpt
        featuredImage {
          node {
            altText
            localFile {
              url
              childImageSharp {
                gatsbyImageData(
                  webpOptions: { quality: 90 }
                  width: 800
                  formats: WEBP
                  layout: CONSTRAINED
                  aspectRatio: 1.55
                )
              }
            }
          }
        }
      }
    }
    wpMediaItem(databaseId: { eq: 23640 }) {
      title
      altText
      localFile {
        url
        childImageSharp {
          gatsbyImageData(
            webpOptions: { quality: 90 }
            width: 1920
            formats: WEBP
            layout: FIXED
            aspectRatio: 2
          )
        }
      }
    }
  }
`

export const Head = () => {
  return <Seo title="Essays | TransTech Social Enterprises" />
}
